<template>
	<!-- contents -->
	<div class="cw-app-wrapper pre-contract">
		<div class="cw-app-contents">
			<div class="pre-subs-head">
				<div class="inner">
					<div class="u-select-box">
						<div class="col-lt">
							<pre-contract-status-popup
								title="사전계약 상태"
								v-model="subsStatus"
								:items="subsStatusItems"
								@item-selected="handleSubsStatusSelected"
							>
								<template>
									<em class="value">{{ subsStatusDisplayText }}</em>
									<i class="ico"></i>
								</template>
							</pre-contract-status-popup>
							<pre-contract-status-popup
								title="남은 도래월"
								v-model="monthArrival"
								:items="monthArrivalItems"
								@item-selected="handleMonthArrivalSelected"
							>
								<template>
									<em class="value">{{ monthArrivalDisplayText }}</em>
									<i class="ico"></i>
								</template>
							</pre-contract-status-popup>
						</div>
						<div class="col-rt">
							<v-btn text default class="btn-refresh" @click="getReload()">
								<em class="ir">새로고침</em>
							</v-btn>
						</div>
					</div>

					<div class="u-select-view">
						<div class="info-total">
							<em
								>전체<strong class="black">{{
									count.ready + count.completed + count.unavailable
								}}</strong
								>건</em
							>
						</div>
						<div class="info-detail">
							<ul>
								<li class="blue">
									<em
										>계약가능<strong>{{ count.ready }}</strong></em
									>
								</li>
								<li>
									<em
										>배정대기<strong>{{ count.completed }}</strong></em
									>
								</li>
								<li>
									<em
										>계약불가<strong>{{ count.unavailable }}</strong></em
									>
								</li>
							</ul>

							<!-- popup -->
							<v-dialog width="312" v-model="popPreSubsShow">
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon v-bind="attrs" v-on="on">
										<em class="ir">팝업 열기</em>
									</v-btn>
								</template>
								<section class="u-confirm-wrap">
									<article class="panels">
										<v-card-title>사전계약 진행 구분 안내</v-card-title>
										<v-card-text>
											<ul class="u-confirm-text">
												<li>
													<h5>계약가능 건수</h5>
													<p>
														계정 도래월이 1개월~4개월(상담신청된 자가관리 계정은
														6개월) 남은 경우로 재렌탈 사전계약 접수가 가능한
														기존 계정 건수
													</p>
												</li>
												<li>
													<h5>배정대기 건수</h5>
													<p>
														사전계약 접수 후 전자계약 완료까지 진행되어 배정
														대기중인 기존 계정 건수
													</p>
												</li>
												<li>
													<h5>계약불가 건수</h5>
													<p>
														폰패드 계약 건, 가해약 진행 건, 당월이 소유권
														도래월인 경우 등의 사유로 재렌탈 사전계약 접수가
														불가한 건수
													</p>
												</li>
											</ul>
										</v-card-text>
									</article>
									<article class="bottom">
										<v-card-actions>
											<div class="col">
												<v-btn
													class="btn-confirm"
													@click="popPreSubsShow = false"
												>
													닫기
												</v-btn>
											</div>
										</v-card-actions>
									</article>
								</section>
							</v-dialog>
							<!-- //popup -->
						</div>
					</div>
				</div>
			</div>

			<div class="pre-subs-sort">
				<div class="inner">
					<v-btn-toggle
						v-model="customerSort"
						text
						group
						class="u-toggle-sort"
						:mandatory="true"
					>
						<v-btn v-for="(sortItem, sortIndex) in sortType" :key="sortIndex">{{
							sortItem.text
						}}</v-btn>
					</v-btn-toggle>
				</div>
			</div>

			<div class="pre-subs-table" v-if="customerList.length > 0">
				<div class="thead" :class="{ fixed: scrolled }">
					<div class="th">고객명</div>
					<div class="th">전체</div>
					<div class="th">계약<br />가능</div>
					<div class="th">배정<br />대기</div>
					<div class="th">계약<br />불가</div>
				</div>

				<div class="tbody">
					<template v-for="(customer, i) in customerList">
						<div
							class="tr"
							:key="`item-${i}`"
							@click="goDetail(customer)"
							v-if="
								customer.count.ready +
									customer.count.completed +
									customer.count.unavailable >
									0
							"
						>
							<div class="td">
								<p class="type">
									{{ customer.CUST_TYPE === '1' ? '개인' : '개인사업자' }}
								</p>
								<p
									class="company"
									:class="{
										long:
											customer.CUST_NM.length > 14 ||
											customer.BUSI_NM.length > 7
									}"
								>
									{{
										customer.CUST_TYPE === '1'
											? customer.CUST_NM
											: customer.BUSI_NM
									}}
								</p>
							</div>
							<div class="td">
								{{
									customer.count.ready +
										customer.count.completed +
										customer.count.unavailable
								}}
							</div>
							<div :class="['td', customer.count.ready > 0 ? 'blue' : '']">
								{{ customer.count.ready }}
							</div>
							<div class="td">{{ customer.count.completed }}</div>
							<div class="td">{{ customer.count.unavailable }}</div>
						</div>
					</template>
				</div>
			</div>

			<div
				class="pre-no-data"
				v-else-if="customerList.length === 0 && !loading"
			>
				<img
					src="/img/img_no_data.png"
					alt="조회중입니다. 잠시만 기다려주세요."
				/>
				<p>조회중입니다.<br />잠시만 기다려주세요.</p>
			</div>

			<div class="pre-no-data" v-else>
				<img
					src="/img/img_no_data.png"
					alt="사전계약을 신청할 수 있는 계정이
						없습니다."
				/>
				<p>사전계약 대상이 되는 계정이 없습니다.</p>
			</div>
		</div>
		<!-- //contents -->
	</div>
</template>

<script>
import filters from '@/mixins/filters'
import moment from 'moment'
import { registerCallbackToApp, closePopView } from '@/utils/navigation'
import PreContractStatusPopup from './PreContractStatusPopup.vue'
import { getPreContractData } from '@/apis/pre.contract.api'

/**
 * 모바일 사전 계약 리스트
 * @description
 * 조직체크 : CL, HC (W:IN, BB 조직은 제외)
 * 판매인 계정별 관리 고객의 사전 계약 가능 주문번호 목록 노출
 *
 *1. 계약가능: N(계약대기), A(계약취소)
 *2. 배정대기: R(배정대기)
 *3. 계약불가: S(계약완료), P(폰패드계약), F(계약불가), E(기간만료)
 */

export default {
	name: 'PreContractList',
	mixins: [filters],
	components: {
		PreContractStatusPopup
	},
	watch: {
		customerSort() {
			this.refreshList()
		}
	},
	data() {
		return {
			// 고객 정렬
			customerSort: 0,
			sortType: [
				{ code: '0', text: '고객명순' },
				{ code: '1', text: '소유권 도래월순' }
			],
			// ScrollTop
			scrolled: false,
			// 사전계약상태
			subsStatus: '0',
			subsStatusDisplayText: '상태모두보기',
			subsStatusItems: [
				{
					code: '0',
					text: '상태모두보기'
				},
				{
					code: '1',
					text: '계약가능'
				},
				{
					code: '2',
					text: '배정대기'
				},
				{
					code: '3',
					text: '계약불가'
				}
			],
			// 남은도래월
			monthArrival: '',
			monthArrivalDisplayText: '전체기간',
			monthArrivalItems: [
				{
					code: '',
					text: '전체기간 '
				},
				{
					code: '0',
					text: 'M(소유권 도래월)'
				},
				{
					code: '1',
					text: 'M-1(소유권도래 1개월 전)'
				},
				{
					code: '2',
					text: 'M-2(소유권도래 2개월 전)'
				},
				{
					code: '3',
					text: 'M-3(소유권도래 3개월 전)'
				},
				{
					code: '4',
					text: 'M-4(소유권도래 4개월 전)'
				},
				{
					code: '5',
					text: 'M-5(소유권도래 5개월 전)'
				},
				{
					code: '6',
					text: 'M-6(소유권도래 6개월 전)'
				}
			],
			popPreSubsShow: false,
			codyNo: '',
			customerData: [],
			orderData: [],
			assignData: [],
			count: { ready: 0, completed: 0, unavailable: 0 },
			customerList: [],
			loading: false
		}
	},
	async created() {
		window.addEventListener('scroll', this.handleScroll)
		this.codyNo = this.$store.getters['common/codyNo']
	},
	async mounted() {
		await this.reloadData()
		await this.refreshList()
	},
	activated() {
		this.initAppPage()
		this.refreshList()
	},
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll)
	},
	methods: {
		// 애플리케이션 설정
		initAppPage() {
			registerCallbackToApp(
				{
					title: '재렌탈 사전계약',
					cbPhysicalBack: 'cbPhysicalBack',
					isNotUseDsHeaderPhysicalBack: true
				},
				this
			)
		},
		async cbPhysicalBack() {
			const ok = await this.$alert({
				message: '홈화면으로 이동하시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			})
			if (ok) closePopView()
		},

		async getReload() {
			await this.reloadData()
			await this.refreshList()
		},

		// ScrollTop
		handleScroll() {
			this.scrolled = window.scrollY > 230
		},

		async reloadData() {
			const params = {
				saleCd: this.$store.getters['common/codyNo']
			}

			this.loading = false

			await getPreContractData(params)
				.then(res => {
					this.loading = true

					this.customerData = res.resultObject.ET_CUST
					this.orderData = res.resultObject.ET_ORDER.filter(y => {
						if (y.DMONTH < 5) {
							return true
						}
						return ['5', 'G'].includes(y.USE_TP) && y.CODY_CNSL_YN === 'Y'
					})

					this.assignData = res.resultObject.ET_ASSIGN
				})
				.catch(() => {
					this.$alert({
						message:
							'일시적인 통신 장애로 서비스에 접속할 수 없습니다. 문제가 계속된다면, 담당자에게 문의해 주세요.'
					})
				})
		},

		async refreshList() {
			this.customerList = []
			this.count.ready = 0
			this.count.completed = 0
			this.count.unavailable = 0

			const now = moment()

			this.customerData.forEach(res => {
				let customer = {
					...res,
					orders: [],
					count: {
						ready: 0,
						completed: 0,
						unavailable: 0
					},
					days: Number.MAX_SAFE_INTEGER,
					notExistsM_0: true,
					notExistsM_1: true,
					notExistsM_2: true,
					notExistsM_3: true,
					notExistsM_4: true,
					notExistsM_5: true,
					notExistsM_6: true
				}
				this.orderData
					.filter(x => x.KUNNR === res.KUNNR)
					.forEach(v => {
						customer.orders.push(v)

						v.assignees = this.assignData.filter(x => x.ORDER_NO === v.ORDER_NO)

						if (v.SUBSTATUS === 'N' || v.SUBSTATUS === 'A') {
							customer.count.ready += 1
						} else if (v.SUBSTATUS === 'R') {
							customer.count.completed += 1
						} else {
							customer.count.unavailable += 1
						}

						const days = moment(v.LAST_DT).diff(now, 'days')
						if (customer.days > days) {
							customer.days = days
						}

						if (v.DMONTH === '0') customer.notExistsM_0 = false
						else if (v.DMONTH === '1') customer.notExistsM_1 = false
						else if (v.DMONTH === '2') customer.notExistsM_2 = false
						else if (v.DMONTH === '3') customer.notExistsM_3 = false
						else if (v.DMONTH === '4') customer.notExistsM_4 = false
						else if (v.DMONTH === '5') customer.notExistsM_5 = false
						else if (v.DMONTH === '6') customer.notExistsM_6 = false
					})

				// 상태 필터링
				if (
					(this.subsStatus === '1' && customer.count.ready === 0) ||
					(this.subsStatus === '2' && customer.count.completed === 0) ||
					(this.subsStatus === '3' && customer.count.unavailable === 0)
				) {
					return
				}

				// 도래월 필터링
				if (
					(this.monthArrival === '0' && customer.notExistsM_0) ||
					(this.monthArrival === '1' && customer.notExistsM_1) ||
					(this.monthArrival === '2' && customer.notExistsM_2) ||
					(this.monthArrival === '3' && customer.notExistsM_3) ||
					(this.monthArrival === '4' && customer.notExistsM_4) ||
					(this.monthArrival === '5' && customer.notExistsM_5) ||
					(this.monthArrival === '6' && customer.notExistsM_6)
				) {
					return
				}

				// 상태, 도래월 필터링
				if (this.subsStatus !== '0' && this.monthArrival !== '') {
					let noMatching = true
					const customerOrderList = customer.orders.filter(
						v => v.DMONTH === this.monthArrival
					)
					// 계약가능
					if (
						this.subsStatus === '1' &&
						customerOrderList.some(v => ['A', 'N'].includes(v.SUBSTATUS))
					) {
						noMatching = false
					}
					// 배정대기
					else if (
						this.subsStatus === '2' &&
						customerOrderList.some(v => 'R' === v.SUBSTATUS)
					) {
						noMatching = false
					}
					// 계약불가
					else if (
						this.subsStatus === '3' &&
						customerOrderList.some(v => !['A', 'N', 'R'].includes(v.SUBSTATUS))
					) {
						noMatching = false
					}

					if (noMatching) return
				}

				this.count.ready += customer.count.ready
				this.count.completed += customer.count.completed
				this.count.unavailable += customer.count.unavailable

				this.customerList.push(customer)
			})

			this.sort()
		},

		sort() {
			let compare

			if (this.customerSort === 0) {
				compare = (a, b) => {
					if (a.CUST_NM > b.CUST_NM) return 1
					if (a.CUST_NM < b.CUST_NM) return -1
					if (a.CUST_NM === b.CUST_NM) {
						if (a.count.ready < b.count.ready) return 1
						if (a.count.ready > b.count.ready) return -1
					}
					return 0
				}
			} else if (this.customerSort === 1) {
				compare = (a, b) => {
					if (a.days > b.days) return 1
					if (a.days < b.days) return -1
					if (a.days === b.days) {
						if (a.count.ready < b.count.ready) return 1
						if (a.count.ready > b.count.ready) return -1
					}
					return 0
				}
			}

			if (!compare) return

			this.customerList.sort(compare)
		},
		// 사전계약 상태
		handleSubsStatusSelected(item) {
			this.subsStatusDisplayText = item.text
			this.refreshList()
		},
		// 남은도래월
		handleMonthArrivalSelected(item) {
			this.monthArrivalDisplayText = item.text
			this.refreshList()
		},
		goDetail(item) {
			this.$emit('replaceComponent', {
				name: 'PreContractListDetail',
				params: item,
				id: item.KUNNR
			})
		}
	}
}
</script>

<style scoped></style>
