<template>
	<div class="cw-app-wrapper pre-contract">
		<!-- contents -->
		<div class="cw-app-bgcolor">
			<div class="pre-user-info">
				<!-- 고객정보 -->
				<div class="infobox">
					<div class="profile">
						<p class="name">
							{{
								customer.CUST_TYPE === '1' ? customer.CUST_NM : customer.BUSI_NM
							}}
						</p>
						<p class="phone">
							{{ customer.KUNNR }}
							<v-btn
								icon
								@click="onClickUserInfo()"
								:class="{ active: isToggle }"
							>
								<v-icon>mdi-chevron-down</v-icon>
							</v-btn>
						</p>
					</div>
					<div class="contact">
						<a :href="`tel:${customer.MOB_NUMBER}`" class="call">
							<em class="ir">전화</em>
						</a>
						<a :href="`sms:${customer.MOB_NUMBER}`" class="sms">
							<em class="ir">메시지</em>
						</a>
					</div>
				</div>
				<div class="infobox" :class="{ active: isToggle }">
					<div class="address">
						<dl v-if="customer.MOB_NUMBER">
							<dt class="dot">휴대폰 번호</dt>
							<dd v-if="custInfo === false">
								{{ $options.filters.phoneFormat(customer.MOB_NUMBER, 0) }}
							</dd>
							<dd v-else>
								{{ $options.filters.phoneFormat(customer.MOB_NUMBER, 1) }}
							</dd>
						</dl>
						<dl v-if="customer.TEL_NUMBER">
							<dt class="dot">자택번호</dt>
							<dd v-if="custInfo === false">
								{{ $options.filters.phoneFormat(customer.TEL_NUMBER, 0) }}
							</dd>
							<dd v-else>
								{{ $options.filters.phoneFormat(customer.TEL_NUMBER, 1) }}
							</dd>
						</dl>
						<dl>
							<dt class="dot">계약자 주소</dt>
							<dd v-if="custInfo === false">
								[{{
									customer.POST_CODE !== undefined
										? customer.POST_CODE.replaceAll('-', '')
										: ''
								}}]
								{{ $options.filters.addr(customer.ADDR1, customer.ADDR2, 0) }}
							</dd>
							<dd v-else>
								[{{
									customer.POST_CODE !== undefined
										? customer.POST_CODE.replaceAll('-', '')
										: ''
								}}] {{ customer.ADDR1 }} {{ customer.ADDR2 }}
							</dd>
						</dl>
					</div>
					<div class="u-switch-box">
						<v-switch
							v-model="custInfo"
							:label="custInfo === true ? `고객정보 열람` : `고객정보 숨김`"
						>
						</v-switch>
					</div>
				</div>
			</div>
			<!--// 고객정보 -->

			<!-- 사전계약 카운트 -->
			<div class="pre-user-status">
				<p class="total">
					전체<em>{{ count.total }}</em
					>건
				</p>
				<ul class="list">
					<li>
						<p class="tit">계약가능</p>
						<p class="num blue">
							<em>{{ count.ready }}</em
							>건
						</p>
					</li>
					<li>
						<p class="tit">배정대기</p>
						<p class="num">
							<em>{{ count.completed }}</em
							>건
						</p>
					</li>
					<li>
						<p class="tit">계약불가</p>
						<p class="num">
							<em>{{ count.unavailable }}</em
							>건
						</p>
					</li>
				</ul>
			</div>
			<!--// 사전계약 카운트 -->

			<!-- 사전계약 주문 리스트 -->
			<div class="pre-user-detail">
				<ul>
					<li v-for="(order, index) in orders" :key="index">
						<div class="check">
							<div class="col-b" @click="onClickPrdInfo(order.details)">
								<p class="state">
									<em
										:class="{
											blue: order.SUBSTATUS === 'N' || order.SUBSTATUS === 'A'
										}"
									>
										{{ getDisplayStatus(order.SUBSTATUS) }}
									</em>
									<em v-if="['5', 'G'].includes(order.USE_TP)">자가관리</em>
									<em
										v-if="order.DMONTH == '0'"
										v-bind:style="{ width: '35.76px', textAlign: 'center' }"
									>
										{{ ' M ' }}
									</em>
									<em v-else-if="order.DMONTH !== '0'">
										{{ `M-${order.DMONTH}` }}
									</em>
									<i v-if="order.SUBSTATUS === 'R'">
										배정가능 기간 {{ order.ABLEDAY }}부터
									</i>
								</p>

								<p class="model">
									<em
										>{{ order.CONTRACT_ID === '1' ? '일시불' : '렌탈' }}
										<i
											>{{ getPrdName(order.PRODH) }} {{ order.GOODS_NM }}</i
										></em
									>
								</p>
							</div>

							<div class="col-c" @click="onClickPrdInfo(order.details)">
								<v-btn icon :class="{ active: order.details.opened }">
									<v-icon>mdi-chevron-down</v-icon>
								</v-btn>
							</div>
						</div>
						<div class="infos" :class="{ active: order.details.opened }">
							<div class="u-switch-box">
								<v-switch
									v-model="order.details.masking"
									:label="
										!order.details.masking ? `상세주소 숨김` : `상세주소 열람`
									"
								>
								</v-switch>
							</div>
							<div class="card">
								<h4>계정정보</h4>
								<dl>
									<dt>주문번호</dt>
									<dd>{{ order.ORDER_NO }}</dd>
								</dl>
								<dl v-if="order.CONTRACT_ID === '2'">
									<dt>월렌탈료</dt>
									<dd>
										{{
											order.MONTH_AMT.replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
												'원'
										}}
									</dd>
								</dl>
								<dl>
									<dt>최초설치일</dt>
									<dd>{{ order.FIRST_INST_DT }}</dd>
								</dl>
								<dl
									v-if="
										order.SALEMOD_DT !== '0000-00-00' && order.SALEMOD_DT !== ''
									"
								>
									<dt>매변설치일</dt>
									<dd>{{ order.SALEMOD_DT }}</dd>
								</dl>
								<dl v-else>
									<dt>매변설치일</dt>
									<dd>-</dd>
								</dl>
								<dl>
									<dt>도래일자</dt>
									<dd class="blue">
										{{ order.LAST_DT }}
									</dd>
								</dl>
								<dl>
									<dt>관리유형</dt>
									<dd>
										{{
											['5', 'G'].includes(order.USE_TP)
												? '자가관리'
												: '방문관리'
										}}
									</dd>
								</dl>
								<dl>
									<dt>설치주소</dt>
									<dd v-if="order.details.masking">
										[{{
											customer.POST_CODE !== undefined
												? customer.POST_CODE.replaceAll('-', '')
												: ''
										}}] {{ order.ADDR1 }} {{ order.ADDR2 }}
									</dd>
									<dd v-else>
										[{{
											customer.POST_CODE !== undefined
												? customer.POST_CODE.replaceAll('-', '')
												: ''
										}}] {{ $options.filters.addr(order.ADDR1, order.ADDR2, 0) }}
									</dd>
								</dl>
							</div>
							<div
								class="card"
								v-if="
									!(
										(order.SUBSTATUS === 'N' || order.SUBSTATUS === 'A') &&
										order.CANC_DT === '0000-00-00'
									)
								"
							>
								<div>
									<h4>
										사전계약 정보
									</h4>
									<div>
										<dl v-if="order.COMP_DT !== '0000-00-00'">
											<dt>계약 완료일시</dt>
											<dd>{{ order.COMP_DT }} {{ order.COMP_TM }}</dd>
										</dl>
										<dl v-if="order.CANC_DT !== '0000-00-00'">
											<dt>계약 삭제일시</dt>
											<dd>{{ order.CANC_DT }} {{ order.CANC_TM }}</dd>
										</dl>
										<dl v-if="order.SUBSTATUS === 'R'">
											<dt>배정 가능기간</dt>
											<dd>{{ order.ABLEDAY }}부터</dd>
										</dl>
										<dl v-if="order.SUBSTATUS === 'R'">
											<dt>사전계약 혜택</dt>
											<dd>{{ order.BENEFIT }}</dd>
										</dl>
										<dl
											v-if="
												!(
													order.SUBSTATUS === 'N' ||
													order.SUBSTATUS === 'A' ||
													order.SUBSTATUS === 'R' ||
													order.SUBSTATUS === 'S'
												)
											"
										>
											<dt>계약 불가사유</dt>
											<dd>
												{{ order.REASON }}
											</dd>
										</dl>
									</div>
								</div>
							</div>
							<p class="msg" v-if="order.SUBSTATUS === 'R'">
								※ 사전계약 혜택은 계약 완료 월, 주문 상품에 따라 <br />
								달라질 수 있습니다.
							</p>
							<div class="card">
								<h4>
									<span class="cate">관리판매인 위수임내역</span>
									<span class="type">
										<i class="ico"></i><em>사전계약완료 판매인</em>
									</span>
								</h4>
								<div v-for="(assignee, i) in order.assignees" :key="i">
									<dl>
										<dt>{{ assignee.ASSIGN_DT }}</dt>
										<dd>
											{{ assignee.GK_NM }} {{ assignee.CODY_NM }}({{
												assignee.CODY_ID
											}})
											<i class="ico" v-if="assignee.PRE_CHK === 'X'"></i>
										</dd>
									</dl>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<!-- 사전계약 주문 리스트 -->
		</div>
		<!-- //contents -->

		<!-- bottom -->
		<div class="cw-app-fixed">
			<div class="u-btns-group">
				<div class="column">
					<v-btn large depressed class="u-btn-text" @click="prev()">
						이전
					</v-btn>
				</div>
				<div class="column">
					<v-btn
						large
						depressed
						class="u-btn-active"
						@click="next()"
						:disabled="disabled"
					>
						다음
					</v-btn>
				</div>
			</div>
		</div>
		<!-- //bottom -->
	</div>
</template>

<script>
import filters from '@/mixins/filters'
import { registerCallbackToApp } from '@/utils/navigation'
import { getPrdName } from '@/utils/ec'

/**
 * 모바일 사전계약 리스트
 * @description
 * 조직체크 : CL, HC (W:IN, BB 조직은 제외)
 * 판매인 계정별 관리 고객의 사전계약 가능 주문번호 목록 노출
 */

export default {
	name: 'PreContractListDetail',
	mixins: [filters],
	props: {
		params: Object
	},
	data() {
		return {
			count: {},
			name: '',
			kunnr: '',
			customer: {},
			orders: [],
			isToggle: false, // 고객 상세정보
			custInfo: false, // 고객정보 열람
			disabled: true,
			details: Array
		}
	},

	activated() {
		this.initAppPage()

		if (!this.params) {
			return
		}

		this.customer = this.params
		this.orders = this.params.orders

		this.count = this.customer.count
		this.count.total = 0
		if (this.count.ready > 0) this.disabled = false
		Object.values(this.count).forEach(v => (this.count.total += v))

		this.details = []
		for (const order of this.orders) {
			const detail = {
				checked: order.SUBSTATUS === 'N' || order.SUBSTATUS === 'A',
				opened: false,
				masking: false
			}

			this.details.push(detail)

			order.details = detail
		}

		let params = { orderNoList: [] }
		this.orders.forEach(v => {
			params.orderNoList.push(v.ORDER_NO)
		})

		this.refreshList()
	},

	methods: {
		getPrdName,
		onClickUserInfo() {
			this.isToggle = !this.isToggle

			if (this.isToggle) {
				this.custInfo = false
			}
		},

		onClickPrdInfo(details) {
			const opened = !details.opened

			this.details.forEach(x => (x.opened = false))

			details.opened = opened

			if (details.opened) {
				details.masking = false
			}
		},

		getDisplayStatus(val) {
			let status = ''
			switch (val) {
				case 'N':
				case 'A':
					status = '계약가능'
					break
				case 'R':
					status = '배정대기'
					break
				case 'S':
				case 'F':
				case 'P':
				case 'E':
					status = '계약불가'
					break
			}
			return status
		},

		initAppPage() {
			registerCallbackToApp(
				{
					title: '재렌탈 사전계약',
					cbPhysicalBack: 'cbPhysicalBack'
				},
				this
			)
		},

		async cbPhysicalBack() {
			this.prev()
		},

		/**
		 * 이전
		 */
		prev() {
			this.$emit('replaceComponent', {
				name: 'PreContractList'
			})
		},

		/**
		 * 예약접수
		 */
		next() {
			const params = {
				customer: this.customer
			}

			this.$emit('replaceComponent', {
				name: 'PreContractPost',
				params: params
			})
		},

		/**
		 * 사전계약 주문목록 정렬
		 */
		refreshList() {
			this.orders.sort((a, b) => {
				// 도래월 정렬
				if (a.DMONTH > b.DMONTH) return 1
				if (a.DMONTH < b.DMONTH) return -1

				// 사전계약상태 정렬
				const getWeight = v => {
					if (v.SUBSTATUS === 'N' || v.SUBSTATUS === 'A') return 0
					else if (v.SUBSTATUS === 'R') return 1
					else if (v.SUBSTATUS === 'S') return 2
					else return 3
				}

				const weightA = getWeight(a)
				const weightB = getWeight(b)

				if (weightA > weightB) return 1
				if (weightA < weightB) return -1

				return 0
			})
		}
	}
}
</script>

<style scoped></style>
