<template>
	<div>
		<v-bottom-sheet
			scrollable
			v-model="seen"
			:persistent="persistent"
			:content-class="`u-bottom-sheet`"
		>
			<template v-slot:activator="{ attrs }" v-if="!hideButton">
				<v-btn
					outlined
					@click="open"
					v-bind="attrs"
					:disabled="disabled"
					class="select-area"
				>
					<slot name="default" />
				</v-btn>
			</template>

			<v-card class="u-bottom-list">
				<v-card-title class="tit">
					{{ title }}
				</v-card-title>
				<v-card-text>
					<v-btn-toggle
						v-model="selected"
						@change="onChange"
						:mandatory="mandatory"
						class="u-bottom-item"
					>
						<ul>
							<li v-for="(item, i) in items" :key="`item-${i}`">
								<v-btn
									:ripple="false"
									@click="onClick(i)"
									class="select-target"
								>
									{{ item.text }}
									<span v-if="['5', '6'].includes(item.code)" class="sub-desc">
										* 자가관리 계정 전용
									</span>
								</v-btn>
							</li>
						</ul>
					</v-btn-toggle>
				</v-card-text>
			</v-card>
		</v-bottom-sheet>
	</div>
</template>

<script>
export default {
	props: {
		text: String,
		title: String,
		persistent: Boolean,
		hideButton: Boolean,
		value: [String, Number],
		items: {
			type: Array,
			default: () => {
				return []
			}
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		displayText() {
			return this.text
		},
		mandatory() {
			return this.items.some(x => x.code === this.value)
		},
		selected: {
			get() {
				return this.items.findIndex(x => x.code === this.value)
			},
			set(value) {
				if (value !== undefined) {
					this.$emit('input', this.items[value].code)
				}
			}
		}
	},
	data() {
		return {
			seen: false
		}
	},
	methods: {
		open() {
			this.seen = true
		},
		onClick(e) {
			if (e === this.selected) {
				this.onChange(e) //동일항목선택
			}
		},
		onChange(e) {
			const selectedItem = this.items[e]
			this.$emit('item-selected', selectedItem)
			this.seen = false
		}
	}
}
</script>
